import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setGY } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";


function Gy(){
   
    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.api);

    let current_Gy = myData.Gy[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.Gy)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        

    return(
        <>
            <div className="parameter" id="gy" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="gy" checked={checked} onChange={(e) => {dispatch(setGY(e.target.checked))}} />
                    <label htmlFor="gy" >Gy</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current Gy : {parseFloat(current_Gy).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#ffd9c0"} />}
        </>
    );
}

export default Gy;