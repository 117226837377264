import axios from 'axios';
import e from 'cors';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setApiData } from '../redux/apidata';

// let mac_add = prompt("Enter the mac address","84:F7:03:67:16:C0");
// let limit = prompt("Enter the limit of data","100");


function Getdata({mac, limit}){

    const dispatch = useDispatch();
   

   useEffect(() => {

        setTimeout(()=>{
            axios.get(`http://app.napinotech.com:3000/app/v1/data/stem/${mac}/${limit}`)
            .then((response) =>{
                // console.log(response.data);
                const processed_data = {
                    humidity : [],
                    temperature : [],
                    lightexposure : [],
                    motion : [],
                    time : [],
                    coordinate : [],
                    lat : [],
                    long : [],
                    nos : [],
                    h_dop : [],
                    speed : [],
                    n_s : [],
                    e_w : [],
                    when : [],
                    epoch : [],
                    battery :[],
                    voltage :[],
                    pressure : [],
                    altitude : [],
                    Gx : [],
                    Gy : [],
                    Gz : [],
                    Err : [],
                    IMEI : [],
                    CSQ : [],
                    course : [],
                    FWv : [],
                    RR : [],
                    map_humidity : [],
                    map_temperature : [],
                    map_lightexposure : [],
                    map_motion : [],
                    map_altitude : [],
                    map_course : [],
                    
                }
                let count=0;
                response.data.forEach(ele =>{
                   
                    if(!isNaN(ele.H) && ((ele.H)!= 0)){
                        processed_data.humidity.push(Number(ele.H));
                    }
                        
                    if(!isNaN(ele.T) && ((ele.T)!= 0)){
                        processed_data.temperature.push(Number(ele.T));
                    }

                    if(!isNaN(ele.Lux)){
                        processed_data.lightexposure.push(Number(ele.Lux));
                    }

                    if(!isNaN(ele.Evt)){
                        
                        if(ele.Evt==1 || ele.Evt==2 || ele.Evt==3){
                            // count++;
                            processed_data.motion.push(ele.Evt);
                        }
                        else if(ele.Evt==0){
                            processed_data.motion.push(ele.Evt);
                        }
                    }

                    if((!isNaN(ele.Lat) && ele.Lat!=0) || (!isNaN(ele.Lon) && ele.Lon!=0)){
                        processed_data.coordinate.push([Number(ele.Lat), Number(ele.Lon)]);
                    }

                    if(!isNaN(ele.Lat) && ele.Lat!=0){
                        processed_data.lat.push(Number(ele.Lat));
                        processed_data.nos.push(Number(ele.NoS));
                        processed_data.h_dop.push(Number(ele.H_DOP));
                        processed_data.speed.push(Number(ele.Speed));
                        processed_data.n_s.push(Number(ele.N_S));
                        processed_data.e_w.push(Number(ele.E_W));
                        processed_data.when.push((ele.when));
                        processed_data.epoch.push(Number(ele.Epoch));
                        processed_data.map_humidity.push(Number(ele.H));
                        processed_data.map_temperature.push(Number(ele.T));
                        processed_data.map_lightexposure.push(Number(ele.Lux));
                        processed_data.map_motion.push(Number(ele.Evt));
                        processed_data.map_altitude.push(Number(ele.Alt));
                        processed_data.map_course.push(Number(ele.Course));

                    }

                    if(!isNaN(ele.Lon) && ele.Lon!=0){
                        processed_data.long.push(Number(ele.Lon));
                    }

                    // if(!isNaN(ele.BAT) && (ele.BAT>=3.1 && ele.BAT<=4.2)){

                    //     processed_data.battery.push(Number((ele.BAT * 81.81)-243.60));
                    //     // console.log(processed_data.battery);
                    // }

                    if(!isNaN(ele.BAT)){

                        processed_data.voltage.push(Number(ele.BAT));
                        // console.log(processed_data.voltage);
                    }

                    if(!isNaN(ele.P)){

                        processed_data.pressure.push(Number(ele.P));
                        // console.log(processed_data.pressure);
                    }

                    if(!isNaN(ele.Gx)){

                        processed_data.Gx.push(Number(ele.Gx));
                        // console.log(processed_data.Gx);
                    }

                    if(!isNaN(ele.Gy)){

                        processed_data.Gy.push(Number(ele.Gy));
                        // console.log(processed_data.Gy);
                    }

                    if(!isNaN(ele.Gz)){

                        processed_data.Gz.push(Number(ele.Gz));
                        // console.log(processed_data.Gz);
                    }

                    if(!isNaN(ele.Err)){

                        processed_data.Err.push(Number(ele.Err));
                        // console.log(processed_data.Err);
                    }

                    if(!isNaN(ele.IMEI)){

                        processed_data.IMEI.push(Number(ele.IMEI));
                        // console.log(processed_data.IMEI);
                    }


                    if(!isNaN(ele.CSQ)){

                        processed_data.CSQ.push(Number(ele.CSQ));
                        // console.log(processed_data.CSQ);
                    }

                    if(isNaN(ele.FWv)){

                        processed_data.FWv.push((ele.FWv));
                        // console.log(processed_data.FWv);
                    }

                    if(!isNaN(ele.RR)){

                        processed_data.RR.push(Number(ele.RR));
                        // console.log(processed_data.RR);
                    }

                    

                    let d=Number(ele.Epoch*1000);   // epoch time is in seconds, we are converting it into milliseconds
                    let a = new Date(d);
                    // console.log(a)
                    processed_data.time.push(d);
                    
                    
                });
                // console.log(setApiData(processed_data))
                // console.log("API HIT 1st")
                dispatch(setApiData(processed_data));


                // console.log(processed_data.coordinate);
                // console.log(processed_data.time);
            })

            .catch((error) => {
                console.log(error);
            });
        },0);


        setInterval(() => {
    
                axios.get(`http://app.napinotech.com:3000/app/v1/data/stem/${mac}/${limit}`)
                .then((response) =>{
                    // console.log(response.data);
                    const processed_data = {
                        humidity : [],
                        temperature : [],
                        lightexposure : [],
                        motion : [],
                        time : [],
                        coordinate : [],
                        lat : [],
                        long : [],
                        nos : [],
                        h_dop : [],
                        speed : [],
                        n_s : [],
                        e_w : [],
                        when : [],
                        epoch : [],
                        battery : [],
                        voltage :[],
                        pressure : [],
                        altitude : [],
                        Gx : [],
                        Gy : [],
                        Gz : [],
                        Err : [],
                        IMEI : [],
                        CSQ : [],
                        course : [],
                        FWv : [],
                        RR : [],
                        map_humidity : [],
                        map_temperature : [],
                        map_lightexposure : [],
                        map_motion : [],
                        map_altitude : [],
                        map_course : [],
                        
                    }
                    let count=0;
                    response.data.forEach(ele =>{
                       
                        if(!isNaN(ele.H) && ((ele.H)!= 0)){
                            processed_data.humidity.push(Number(ele.H));
                        }
                            
                        if(!isNaN(ele.T) && ((ele.T)!= 0)){
                            processed_data.temperature.push(Number(ele.T));
                        }
        
                        if(!isNaN(ele.Lux)){
                            processed_data.lightexposure.push(Number(ele.Lux));
                        }
        
                        if(!isNaN(ele.Evt)){
                            
                            if(ele.Evt==1 || ele.Evt==2 || ele.Evt==3){
                                // count++;
                                processed_data.motion.push(ele.Evt);
                            }
                            else if(ele.Evt==0){
                                processed_data.motion.push(ele.Evt);
                            }
                        }
        
                        if((!isNaN(ele.Lat) && ele.Lat!=0) || (!isNaN(ele.Lon) && ele.Lon!=0)){
                            processed_data.coordinate.push([Number(ele.Lat), Number(ele.Lon)]);
                        }
        
                        if(!isNaN(ele.Lat) && ele.Lat!=0){
                            processed_data.lat.push(Number(ele.Lat));
                            processed_data.nos.push(Number(ele.NoS));
                            processed_data.h_dop.push(Number(ele.H_DOP));
                            processed_data.speed.push(Number(ele.Speed));
                            processed_data.n_s.push(Number(ele.N_S));
                            processed_data.e_w.push(Number(ele.E_W));
                            processed_data.when.push((ele.when));
                            processed_data.epoch.push(Number(ele.Epoch));
                            processed_data.map_humidity.push([Number(ele.H)]);
                            processed_data.map_temperature.push([Number(ele.T)]);
                            processed_data.map_lightexposure.push(Number(ele.Lux));
                            processed_data.map_motion.push(Number(ele.Evt));
                            processed_data.map_altitude.push(Number(ele.Alt));
                            processed_data.map_course.push(Number(ele.Course));
                        }
        
                        if(!isNaN(ele.Lon) && ele.Lon!=0){
                            processed_data.long.push(Number(ele.Lon));
                        }

                       // if(!isNaN(ele.BAT) && (ele.BAT>=3.1 && ele.BAT<=4.2)){

                    //     processed_data.battery.push(Number((ele.BAT * 81.81)-243.60));
                    //     // console.log(processed_data.battery);
                    // }

                    if(!isNaN(ele.BAT)){

                        processed_data.voltage.push(Number(ele.BAT));
                        // console.log(processed_data.voltage);
                    }
                    
                    if(!isNaN(ele.P)){

                        processed_data.pressure.push(Number(ele.P));
                        // console.log(processed_data.pressure);
                    }

                    if(!isNaN(ele.Gx)){

                        processed_data.Gx.push(Number(ele.Gx));
                        // console.log(processed_data.Gx);
                    }

                    if(!isNaN(ele.Gy)){

                        processed_data.Gy.push(Number(ele.Gy));
                        // console.log(processed_data.Gy);
                    }

                    if(!isNaN(ele.Gz)){

                        processed_data.Gz.push(Number(ele.Gz));
                        // console.log(processed_data.Gz);
                    }

                    if(!isNaN(ele.Err)){

                        processed_data.Err.push(Number(ele.Err));
                        // console.log(processed_data.Err);
                    }

                    if(!isNaN(ele.IMEI)){

                        processed_data.IMEI.push(Number(ele.IMEI));
                        // console.log(processed_data.IMEI);
                    }


                    if(!isNaN(ele.CSQ)){

                        processed_data.CSQ.push(Number(ele.CSQ));
                        // console.log(processed_data.CSQ);
                    }

                    if(isNaN(ele.FWv)){

                        processed_data.FWv.push((ele.FWv));
                        // console.log(processed_data.FWv);
                    }

                    if(!isNaN(ele.RR)){

                        processed_data.RR.push(Number(ele.RR));
                        // console.log(processed_data.RR);
                    }

        
        
        
                        let d=Number(ele.Epoch*1000);   // epoch time is in seconds, we are converting it into milliseconds
                        let a = new Date(d);
                        // console.log(a)
                        processed_data.time.push(d);
                        
                        
                    });
                    // console.log(setApiData(processed_data))
                    // console.log("API HIT 2nd");
                    
                    dispatch(setApiData(processed_data));
        
        
                    // console.log(processed_data.coordinate);
                    // console.log(processed_data.time);
                })
        
                .catch((error) => {
                    console.log(error);
                });
            
    
        }, 300000);

       
    });

    return(
        <>

        </>
    );
}

export default Getdata;