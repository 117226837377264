import { FiUpload } from "react-icons/fi";
import { IconContext } from "react-icons/lib";
import { useRef, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";


let processed_data;

function Header({ mac, limit, csv }) {
  let mac1 = mac;
  let limit1 = limit;
  const csvDownloadRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [myData, setMyData] = useState([]);
  const [offlineData, setOfflineData] = useState([]);

  const closing_function = ()=>{
    setOpenModal(false);
    // document.body.style.overflowY = "scroll";
  }

  function Exportpdf() {
    closing_function();
    setTimeout(() => {
      window.print();
    }, 0);
    
  }

  const headers = [
    { label: "Humidity", key: "humidity" },
    { label: "Temperature", key: "temperature" },
    { label: "Light_exposure", key: "light_exposure" },
    { label: "Event/Motion", key: "evt/motion" },
  ];

 
  const fetchData = (mac1,limit1) =>{
    setTimeout(() => {
      axios
        .get(`http://app.napinotech.com:3000/app/v1/data/stem/${mac1}/${limit1}`)
        .then((response) => {
          // console.log(response.data);
          // csvDownloadRef.current.link.click();
          setMyData(response.data);
        
        })

        .catch((error) => {
          console.log(error);
        });
    },0);

    setTimeout(() => {
      closing_function();
    }, 500);

    
  }



  
  const fetchOfflineData = (mac1,limit1) =>{
    let count_Local = false;
    let mainPayload;
    let payloads = [];
    let unstructured_data = [];
    
    
    setTimeout(() => {
      axios
        .get(`http://app.napinotech.com:3000/app/v1/data/offline/stem/${mac1}/${limit1}`)
        .then((response) => {
          // console.log(response.data);
          

          for(let i=0; i<response.data.length; i++){


            if(response.data[i].payload.match(/SOF/gi)){

                if(response.data[i].payload.match(/�/gi)){
                    count_Local = false;
                    continue;
                }

                else if(response.data[i].payload.match(/fota/gi)){
                    count_Local = false;
                    continue;
                }

                else if(response.data[i].payload.match(/as/gi)){
                    count_Local = false;
                    continue;
                }

                else if(count_Local){
                    
                    mainPayload = response.data[i].payload.split("**SOF** ");               // split() function returns a new array
                    // console.log(mainPayload[1]);

                    mainPayload = mainPayload.splice(1,1);                                      // splice() is used to add/remove array elements, it overwrites the original array. Also it returns the removed elements

                    mainPayload = mainPayload[0].replace(/}/g, "}ENDING");                      // replacing "}" with "}ENDING" at the end of every string so that we can use split() function on "ENDING" in order to get separate objects. 
                    mainPayload = mainPayload.split("ENDING");                                  // split() function returns a new array
            
                    mainPayload.splice(-1,1);                                                   // removing empty elements from array by using splice

                    for(let i=0; i<mainPayload.length; i++){
                      payloads.push(JSON.parse(mainPayload[i]));
                    }   
                    
                    // setCount(false);
                    count_Local = false;
                }
            }

            else if(response.data[i].payload.match(/EOF/gi)){
                // setCount(true);
                count_Local = true;
            }

            else{

                mainPayload = response.data[i].payload;                                         // split() function returns a new array
                unstructured_data.push(mainPayload);
                
            }
          }
          
          
          
          
          setOfflineData(payloads);
          console.log(offlineData)
          
          
          
          
        
        })

        .catch((error) => {
          console.log(error);
        });
    },0);

    setTimeout(() => {
      closing_function();
    }, 500);



  }

 


  const Modal = ({open}) => {
    if(!open) return null;
    
    else{
      return (
        <>
        {document.body.style.overflowY = "hidden"}
        <div className="overlay" onClick={closing_function}>
        
          <div className="modalContainer" onClick={(e)=>{e.stopPropagation()}}>
            
              <button className="closing-btn" onClick={closing_function}>
                x
              </button>
            
        
          <CSVLink data={myData} filename="Datapoints.csv" target="_blank" ref={csvDownloadRef} >
            <button className="btn-csv" onClick={()=>{ fetchData(mac1,limit1) }}>Download online API CSV file</button>
          </CSVLink>
          
              
              
          <CSVLink data={offlineData} filename="offline_Datapoints.csv" target="_blank">
              <button className="btn-csv" onClick={()=>{ fetchOfflineData(mac1,limit1) }}>Download offline API CSV file</button>
          </CSVLink>
          
          
              <button className="btn-print-graph" onClick={()=>{Exportpdf()}}>Export PDF</button>
            
          </div>
        </div>
      </>
      );
    }
  };

  return (
    <>
      <div className="header">
        <div className="mac-address">
          <h3>Mac ID - {mac}</h3>
        </div>

        <div className="icon">
          <button className="csvlink-btn" onClick={() => {
           if(window.location.href == `http://iotvisualizer.fightforcomeback.com/${mac}/${limit}`)
           setOpenModal(true);
         else if(window.location.href == `http://iotvisualizer.fightforcomeback.com/${mac}/${limit}/testing`)
           {csv()};
         if(window.location.href == `http://iotvisualizer.fightforcomeback.com/${mac}/${limit}/route`)
           {csv()};
          }}>
            <IconContext.Provider value={{ className: "react-icons" }}>
              <FiUpload />
            </IconContext.Provider>
          </button>
        </div>
      </div>
      {<Modal open={openModal} />}
    </>
  );
}

export default Header;
