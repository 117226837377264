

function Version(){
    return(
        <>
            <div className="version">
                <h6>v1.6.1</h6>
            </div>
        </>
    );
}

export default Version;
