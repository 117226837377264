import React from "react";
import { useState } from "react";
// import { useEffect,useState } from "react";
import { useSelector } from "react-redux";
import IndividualComponentPopup from "./individualcomponentpopup";




function Distancetravelled(){

const [showModal,setShowModal] = useState(false);


    let totalDistanceTravelled = 0;
    let distanceTravelled;

    const myData = useSelector(state => state.api);

    const processed_data = [...myData.coordinate];
    // console.log(processed_data);
    // console.log(processed_data.length);

    const processed_lat = [...myData.lat];
    // console.log(processed_lat);
    // console.log(processed_lat[0]);
    
    const processed_long = [...myData.long];
    // console.log(processed_long);
    // console.log(processed_long[0]);


    

        for (let i = 0; i < processed_data.length-1; i++) {

            function toRad(x) {
              return x * Math.PI / 180;
            }
              
      
                // JavaScript program to calculate Distance Between
                // Two Points on Earth
      
            function distance(lat1,lat2, lon1, lon2)
            {
              
      
              // Haversine formula
              let x2 = lon2 - lon1;
              let dLon = toRad(x2) ;
              let x1 = lat2 - lat1;
              let dLat = toRad(x1);
              let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
                
              let c =  2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
              // Radius of earth in kilometers. Use 3956
              // for miles
              let r = 6371*1000;
      
              // calculate the result
              return(c * r);
            }
      
            // Driver code
              
              let lat1 = processed_lat[i];
              let lat2 = processed_lat[i+1];
              let lon1 = processed_long[i];
              let lon2 = processed_long[i+1];
              let calculated_Distance = distance(lat1, lat2,lon1, lon2);
              // console.log(calculated_Distance + " M");



              totalDistanceTravelled = totalDistanceTravelled + calculated_Distance;
    
        }
        
        if(totalDistanceTravelled<1000){
            distanceTravelled = parseFloat(totalDistanceTravelled).toFixed(2) + " Meters ";
        }
        else{
            distanceTravelled = parseFloat(totalDistanceTravelled/1000).toFixed(2) + " Km ";
        }


        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
    

    return(
        <>
            <div className="parameter" id="distance_travelled"  onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="distance_travelled" />
                    <label htmlFor="distance_travelled" >Distance Travelled</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Total Distance Travelled : {distanceTravelled} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#a8a196"} />}
        </>
    );
}

export default Distancetravelled;