import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setIMEI } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";


function IMEI(){
   
    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.api);

    let current_IMEI = myData.IMEI[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.IMEI)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        

    return(
        <>
            <div className="parameter" id="imei" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="imei" checked={checked} onChange={(e) => {dispatch(setIMEI(e.target.checked))}} />
                    <label htmlFor="imei" >IMEI</label>
                </div>

                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current IMEI : {parseFloat(current_IMEI).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#cbffa9"} />}
        </>
    );
}

export default IMEI;