import React from 'react';

function IndividualComponentPopup(props) {

    // document.getElementsByClassName("overlay").style.backgroundColor = bgcolor;
    
    
        return (
            <>
                <div className="overlay" onClick={props.hider} style={{backgroundColor: "#000"}}>
                    
                    <div className="component-modal-body" style={{backgroundColor: props.bgcolor}} onClick={(e) => { e.stopPropagation() }}>
                        <div className='closing-modal-btn' onClick={props.hider}>
                            &times;
                        </div>

                        <div className="parameter_ul">  
                            <label htmlFor="humidity" >{props.name}</label>
                            <ul>
                                <li type="none">{} <br /> min</li>
                                <li type="none">{} <br /> max</li>
                                <li type="none">{} <br /> avg</li>
                                
                            </ul>
                        </div>

                        <div className="parameter_ul">
                            <label htmlFor="humidity" >{props.name}</label>  
                            <ul>
                                <li type="none">{} <br /> min</li>
                                <li type="none">{} <br /> max</li>
                                <li type="none">{} <br /> avg</li>
                                
                            </ul>
                        </div>
                            
                    </div>    
                </div>       
            </>
      )
    
  
}

export default IndividualComponentPopup;