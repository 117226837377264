import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setRR } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";


function RR(){

    const [showModal,setShowModal] = useState(false);
   

    const myData = useSelector(state => state.api);

    let current_RR = myData.RR[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.RR)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }

    return(
        <>
            <div className="parameter" id="rr" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="rr" checked={checked} onChange={(e) => {dispatch(setRR(e.target.checked))}} />
                    <label htmlFor="rr" >RR</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current RR : {parseFloat(current_RR).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#ff2171"} />}
        </>
    );
}

export default RR;