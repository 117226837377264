import { createSlice } from '@reduxjs/toolkit'

export const optionSlice = createSlice({
  name: 'chartOption',
  initialState: {
    humidity : true,
    temperature : true,
    lightexposure : true,
    motion : true,
    battery : true,
    voltage : false,
    pressure : false,
    Gx : false,
    Gy : false,
    Gz : false,
    Err : false,
    IMEI : false,
    CSQ : false,
    FWv : false,
    RR : false,
    offline_Humidity : true,
    offline_Temperature : true,
    offline_Lightexposure : true,
    offline_Motion : true,
    offline_Voltage :false,
    offline_Pressure : false,
    offline_Altitude : false,
    offline_Gx : false,
    offline_Gy : false,
    offline_Gz : false,
    offline_Err : false,
    offline_CSQ : false,
    offline_RR : false,
    offline_GSM_Err : false,

  },
  reducers: {
   
   setHumidity: (state, action) => {
    state.humidity = action.payload
   },
   setTemperature: (state, action) => {
    state.temperature = action.payload
   },
   setMotion: (state, action) => {
    state.motion = action.payload
   },
   setLightExposure: (state, action) => {
    state.lightexposure = action.payload
   },
   setBattery: (state, action) => {
    state.battery = action.payload
   },
   setVoltage: (state, action) => {
    state.voltage = action.payload
   },
   setPressure: (state, action) => {
    state.pressure = action.payload
   },
   setGX: (state, action) => {
    state.Gx = action.payload
   },
   setGY: (state, action) => {
    state.Gy = action.payload
   },
   setGZ: (state, action) => {
    state.Gz = action.payload
   },
   setERR: (state, action) => {
    state.Err = action.payload
   },
   setIMEI: (state, action) => {
    state.IMEI = action.payload
   },
   setCSQ: (state, action) => {
    state.CSQ = action.payload
   },
   setFWv: (state, action) => {
    state.FWv = action.payload
   },
   setRR: (state, action) => {
    state.RR = action.payload
   },
   setOfflineHumidity: (state, action) => {
    state.offline_Humidity = action.payload
   },
   setOfflineTemperature: (state, action) => {
    state.offline_Temperature = action.payload
   },
   setOfflineLightExposure: (state, action) => {
    state.offline_Lightexposure = action.payload
   },
   setOfflineMotion: (state, action) => {
    state.offline_Motion = action.payload
   },
   setOfflineVoltage: (state, action) => {
    state.offline_Voltage = action.payload
   },
   setOfflinePressure: (state, action) => {
    state.offline_Pressure = action.payload
   },
   setOfflineAltitude: (state, action) => {
    state.offline_Altitude = action.payload
   },
   setOfflineGX: (state, action) => {
    state.offline_Gx = action.payload
   },
   setOfflineGY: (state, action) => {
    state.offline_Gy = action.payload
   },
   setOfflineGZ: (state, action) => {
    state.offline_Gz = action.payload
   },
   setOfflineERR: (state, action) => {
    state.offline_Err = action.payload
   },
   setOfflineCSQ: (state, action) => {
    state.offline_CSQ = action.payload
   },
   setOfflineRR: (state, action) => {
    state.offline_RR = action.payload
   },
   setOfflineGSMErr: (state, action) => {
    state.offline_GSM_Err = action.payload
   },

  }
})

export const { setHumidity, setLightExposure, setMotion, setTemperature, setVoltage, setBattery, setPressure, setGX, setGY, setGZ, setERR, setIMEI, setCSQ, setFWv, setRR, setOfflineHumidity, setOfflineTemperature, setOfflineLightExposure, setOfflineMotion, setOfflineVoltage, setOfflinePressure, setOfflineAltitude, setOfflineGX, setOfflineGY, setOfflineGZ, setOfflineERR, setOfflineCSQ, setOfflineRR, setOfflineGSMErr } = optionSlice.actions

export default optionSlice.reducer

