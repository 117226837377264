import { configureStore } from '@reduxjs/toolkit';
import apiReducer from './apidata.js';
import chartOptionReducer from './chartoptions.js';
import offlineApiReducer from './offlineapidata.js'

export default configureStore({
  reducer: {
    api: apiReducer,
    chartOption : chartOptionReducer,
    offlineApi : offlineApiReducer
  }
})