
import { useDispatch,useSelector } from "react-redux";
import { setOfflineGX } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";



function OfflineGx(){

    const [showModal,setShowModal] = useState(false);
   

    const myData = useSelector(state => state.offlineApi);

    let current_Gx = myData.offline_Gx[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_Gx)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
    

    return(
        <>
            <div className="parameter" id="offline-gx" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="offlinegx" checked={checked} onChange={(e) => {dispatch(setOfflineGX(e.target.checked))}} />
                    <label htmlFor="offlinegx" >Offline &shy;Gx</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current Gx : {parseFloat(current_Gx).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#a8a1969f"} />}
        </>
    );
}

export default OfflineGx;