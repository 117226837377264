import { createSlice } from '@reduxjs/toolkit'

export const apiSlice = createSlice({
  name: 'api',
  initialState: {
    humidity : [],
    temperature : [],
    lightexposure : [],
    motion : [],
    time : [],
    coordinate :[],
    lat: [],
    long:[],
    nos : [],
    h_dop : [],
    speed : [],
    n_s : [],
    e_w : [],
    when : [],
    epoch : [],
    battery : [],
    voltage : [],
    pressure : [],
    altitude : [],
    Gx : [],
    Gy : [],
    Gz : [],
    Err : [],
    IMEI : [],
    CSQ : [],
    course : [],
    FWv : [],
    RR : [],
    map_humidity : [],
    map_temperature : [],
    map_lightexposure : [],
    map_motion : [],
    map_altitude : [],
    map_course : [],
    total_distance_travelled : [],
  },
  
  reducers: {
   setApiData: (state, action) => {
    return action.payload;
   }
  }
})

export const { setApiData } = apiSlice.actions

export default apiSlice.reducer

