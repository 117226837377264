import { useDispatch,useSelector } from "react-redux";
import { setOfflineGSMErr } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";



function OfflineGSMERR(){

    const [showModal,setShowModal] = useState(false);
   

    const myData = useSelector(state => state.offlineApi);

    let current_GSM_ERR = myData.offline_GSM_Err[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_GSM_Err)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        

    return(
        <>
            <div className="parameter" id="offline-gsmerr" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="offlinegsmerr" checked={checked} onChange={(e) => {dispatch(setOfflineGSMErr(e.target.checked))}} />
                    <label htmlFor="offlinegsmerr" >Offline &shy;GSM_ERR</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current GSM_ERR : {parseFloat(current_GSM_ERR).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#9f683ba7"} />}
        </>
    );
}

export default OfflineGSMERR;