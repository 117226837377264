import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './components/combined.css';
import './pages/landingpage.css';
import store from './redux/store.js'
import { Provider } from 'react-redux'
import {
    createBrowserRouter,
    RouterProvider,
    Route
  } from "react-router-dom";

import Landingpage from './pages/landingpage';
import Maptesting from './components/maptesting';
import Mapsales from './components/mapsales';


  const router = createBrowserRouter([      //stores the current location in the browser's address bar using clean URLs and navigates using the browser's built-in history stack.

   
  {
    path: "/",
    element: <Landingpage />,
  },

{
    path: "/:mac/:limit",
    element: <App />,
  },

{
    path: "/:mac/:limit/route",
    element: <Mapsales />,
  },
  
  {
    path: "/:mac/:limit/testing",
    element: <Maptesting />,
  },
  
  ]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store} >           {/*The component makes the Redux store available to any nested components that need to access the Redux store.*/}
      <RouterProvider router={router} />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
