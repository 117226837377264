import { useDispatch,useSelector } from "react-redux";
import { setOfflineGZ } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";



function OfflineGz(){
   
    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.offlineApi);

    let current_Gz = myData.offline_Gz[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_Gz)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        

    return(
        <>
            <div className="parameter" id="offline-gz" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="offlinegz" checked={checked} onChange={(e) => {dispatch(setOfflineGZ(e.target.checked))}} />
                    <label htmlFor="offlinegz" >Offline &shy;Gz</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current Gz : {parseFloat(current_Gz).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#ef626288"} />}
        </>
    );
}

export default OfflineGz;