
import { useDispatch,useSelector } from "react-redux";
import { setOfflineVoltage } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";



function OfflineVoltage(){
   
    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.offlineApi);

    let current_voltage = myData.offline_Voltage[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_Voltage)

        
    const showPopup = () =>{
        setShowModal(true);
    }

    const hidePopup = () =>{
        setShowModal(false);
    }
    

    return(
        <>
            <div className="parameter" id="offline-voltage"  onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="offlinevoltage" checked={checked} onChange={(e) => {dispatch(setOfflineVoltage(e.target.checked))}} />
                    <label htmlFor="offlinevoltage" >Offline &shy;Voltage</label>
                </div>

                <div className="parameter_ul">
                        
                    <ul>
                        <li type="none">Current Voltage : {parseFloat(current_voltage).toFixed(2)} volts<br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#ffff0022"} />}
        </>
    );
}

export default OfflineVoltage;