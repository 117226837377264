import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLightExposure } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";

let L_avg=0;
let L_min=0;
let L_max=0;

function Lightexposure(){

    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.api)
        
        let sum=0;
        
         L_max = Math.max(...myData.lightexposure);
         L_min = Math.min(...myData.lightexposure);


       

        myData.lightexposure.forEach(num =>{
            // if (isNaN(num)) {
            //     num = 0;
            // }
            sum+=num;
        })
        
        L_avg = sum/Object.keys(myData.lightexposure).length;

        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.lightexposure)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
        
       

    return(
        <>
            <div className="parameter" id="lux" onClick={showPopup}>
                <div className="label2">
                    <input type="checkbox" id="lux" checked={checked} onChange={(e) => {dispatch(setLightExposure(e.target.checked))}} />
                    <label htmlFor="lux" >Light Exposure</label>
                </div>

                <div className="parameter_ul">
                    <ul>
                        <li type="none">{L_min.toFixed(2)} <br /> min</li>
                        <li type="none">{L_max.toFixed(2)} <br /> max</li>
                        <li type="none">{L_avg.toFixed(2)} <br /> avg</li>
                                
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#37cb95cc"} />}
        </>
    );
}

export default Lightexposure;