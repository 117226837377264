import './App.css';
import Header from'./components/header';
import Graph from './components/graph';
import Humidity from './components/humidity';
import Temperature from './components/temperature';
import Lightexposure from './components/lightexposure';
import Motion from './components/motion';
import Footer from './components/footer';
import Getdata from './components/getdata';
import {udata} from './components/getdata';
import { useState } from 'react';
import Landingpage from './pages/landingpage';
import { useParams } from 'react-router-dom';
import Version from './components/version';
import Offlineapi from './components/offlineapi';
// import Batterylife from './components/batterylife';
import Voltage from './components/voltage';
import Pressure from './components/pressure';
import Gx from './components/Gx';
import Gy from './components/Gy';
import Gz from './components/Gz';
import Err from './components/Err';
import IMEI from './components/IMEI';
import CSQ from './components/CSQ';
import FWv from './components/FWv';
import RR from './components/RR';
import Distancetravelled from './components/distancetravelled';
// import OnlineApiResponseCounter from './components/onlineapiresponsecounter';
import OfflineHumidity from './components/offlinehumidity';
import OfflineTemperature from './components/offlinetemperature';
import OfflineLightexposure from './components/offlinelightexposure';
import OfflineMotion from './components/offlinemotion';
import OfflineVoltage from './components/offlinevoltage';
import OfflinePressure from './components/offlinepressure';
import OfflineGx from './components/offlineGx';
import OfflineGy from './components/offlineGy';
import OfflineGz from './components/offlineGz';
import OfflineErr from './components/offlineErr';
import OfflineCSQ from './components/offlineCSQ';
import OfflineRR from './components/offlineRR';
import OfflineGSMERR from './components/offlineGSMERR';
import IndividualComponentPopup from './components/individualcomponentpopup';


function App() {
  
  let { mac, limit} = useParams();// param is used if you want to retrieve route parameters from the functional component rendered by the matching route
  // console.log(mac, limit);

  const[a,setA] =useState(0);
  
  function updateA(b){
    setA(b);
  }

  return (
    
    <>
      <div>
        {/* <OnlineApiResponseCounter mac={mac} limit={limit}/> */}
        <Offlineapi mac={mac} limit={limit}/>
        <Getdata mac={mac} limit={limit} />
        
        <Header mac={mac} limit={limit} />
        <Graph mac={mac} limit={limit} updater={updateA}/>
        
        <div className='scrollable_div'>
            {/* <IndividualComponentPopup/>  */}
            <Humidity data={a} />
            <OfflineHumidity/>
            <Temperature />
            <OfflineTemperature/>
            <Lightexposure />
            <OfflineLightexposure/>
            <Motion />
            <OfflineMotion/>
            {/* <Batterylife /> */}
            <Voltage/>
            <OfflineVoltage/>
            <Pressure/>
            <OfflinePressure/>
            <Gx/>
            <OfflineGx/>
            <Gy/>
            <OfflineGy/>
            <Gz/>
            <OfflineGz/>
            <Err/>
            <OfflineErr/>
            <IMEI/>
            <CSQ/>
            <OfflineCSQ/>
            <FWv/>
            <RR/>
            <OfflineRR/>
            <OfflineGSMERR/>
            <Distancetravelled/>
            
        </div>
       
        <Version/>
        <Footer mac={mac} limit={limit} />
        
      </div>
    </>
  );
}

export default App;
