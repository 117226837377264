import { ImStatsDots } from 'react-icons/im';
import { TbRoute } from 'react-icons/tb';
import { IconContext } from 'react-icons/lib';
// import { useRef } from 'react';

function Footer({mac, limit}){

    // function downloadPDF(){
    //     const chartRef = React.useRef(null);
    // }

    return(
        <>
            <div className="footer">
            
                    <div className="stat">
        
                            <a href={`https://iotvisualizer.fightforcomeback.com/${mac}/${limit}`}>
                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                        <div>
                                            <ImStatsDots/>
                                        </div>
                                </IconContext.Provider>
                            </a>
                    </div>


                    <div className="route">
                            <a href={`http://iotvisualizer.fightforcomeback.com/${mac}/${limit}/route`}>
                                <IconContext.Provider value={{ className: 'react-icons' }}>
                                        <div>
                                            <TbRoute  />
                                        </div>
                                </IconContext.Provider>
                            </a>
                    </div>
            
            </div>
        </>
    );
}

export default Footer;