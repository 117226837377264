import { useSelector, useDispatch } from "react-redux";
import { setOfflineMotion } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";

let M_avg=0;
let M_min=0;
let M_max=0;

function OfflineMotion(){

    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.offlineApi)
 
         let sum=0;
         
          M_max = Math.max(...myData.offline_Motion);
          M_min = Math.min(...myData.offline_Motion);
 
 
        
 
         myData.offline_Motion.forEach(num =>{
            // if (isNaN(num)) {
            //     num = 0;
            // }
             sum+=num;
         })
         
         M_avg = sum/Object.keys(myData.offline_Motion).length;

         const dispatch = useDispatch();
         const checked = useSelector(state => state.chartOption.offline_Motion)

         const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
         

    return(
        <>
            <div className="parameter" id="offline-motion" onClick={showPopup}>
                <div className="label2">
                    <input type="checkbox" id="offlinemotion" checked={checked} onChange={(e) => {dispatch(setOfflineMotion(e.target.checked))}} />
                    <label htmlFor="motio">Offline &shy;Evt</label>
                </div>

                <div className="parameter_ul">
                    <ul>
                        <li type="none">{M_min.toFixed(2)} <br /> min</li>
                        <li type="none">{M_max.toFixed(2)} <br /> max</li>
                        <li type="none">{M_avg.toFixed(2)} <br /> avg</li>
                        
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#0df49f60"} />}
        </>
    );
}

export default OfflineMotion;