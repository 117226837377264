import { useSelector, useDispatch } from "react-redux";
import { setTemperature } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";

let T_avg=0;
let T_min=0;
let T_max=0;


function Temperature(){

    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.api)
 
         let sum=0;
         
          T_max = Math.max(...myData.temperature);
          T_min = Math.min(...myData.temperature);
 
         myData.temperature.forEach(num =>{
            // if (isNaN(num)) {
            //     num = 0;
            // }
             sum+=num;
         })
         
         T_avg = sum/Object.keys(myData.temperature).length;

         const dispatch = useDispatch();
         const checked = useSelector(state => state.chartOption.temperature)

         const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
         

    return(
        <>
            <div className="parameter" id="temp" onClick={showPopup}>
                <div className="label2">
                    <input type="checkbox" id="temper" checked={checked} onChange={(e) => {dispatch(setTemperature(e.target.checked))}} />
                    <label htmlFor="temper" >Temperature</label>
                </div>
           
                <div className="parameter_ul">
                    <ul>
                        <li type="none">{T_min.toFixed(2)} <br /> min</li>
                        <li type="none">{T_max.toFixed(2)} <br /> max</li>
                        <li type="none">{T_avg.toFixed(2)} <br /> avg</li>
                        
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#de3c63cc"} />}
        </>
    );
}

export default Temperature;