import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setGX } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";



function Gx(){
   

    const myData = useSelector(state => state.api);

    let current_Gx = myData.Gx[0];

    const [showModal,setShowModal] = useState(false);
        
    const dispatch = useDispatch();
    const checked = useSelector(state => state.chartOption.Gx)

    const showPopup = () =>{
        setShowModal(true);
    }

    const hidePopup = () =>{
        setShowModal(false);
    }
        

    return(
        <>
            <div className="parameter" id="gx" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="gx" checked={checked} onChange={(e) => {dispatch(setGX(e.target.checked))}} />
                    <label htmlFor="gx" >Gx</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current Gx : {parseFloat(current_Gx).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#a8a196"} />}
        </>
    );
}

export default Gx;