import { MapContainer, TileLayer, useMap, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { CircleMarker,Popup,Polyline } from "react-leaflet";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Footer from "./footer";
import Header from "./header";
import { useParams } from 'react-router-dom';
import { CgArrowLongDownC } from 'react-icons/cg';
import { CgShapeCircle } from 'react-icons/cg';
import { IconContext } from 'react-icons/lib';
import Getdata from "./getdata";
import { latLng } from "leaflet";
import { CSVLink } from "react-csv";



let position;
let processed_location_new;
let calculated_Drift =[];
// let center1;


function Maptesting() {

  const [defaultLocation,setDefaultLocation] = useState({val : [28.44372749,77.05503845], asked: false});
  
  
  
 
 
  const csvDownloadRef = useRef(null);


  let { mac, limit, lat_long} = useParams();

    const [myData,setmyData] = useState("");

    

    // useEffect(() => {

    //     axios.get(`http://app.napinotech.com:3000/app/v1/data/stem/84:F7:03:67:26:40/1000`)
    //         .then((response) =>{
    //             console.log(response.data);
    //             const processed_data = {
    //                 lat: [],
    //                 long: []
    //             }
    //             response.data.forEach(ele =>{
    //                 // console.log(ele.lat)

    //                 if(!isNaN(ele.Lat) || !isNaN(ele.Lon)){
    //                   processed_data.lat.push(Number(ele.Lat));
    //                   processed_data.long.push(Number(ele.Lon));
    //                 }
    //             })
    //             // console.log(processed_data);
    //             setmyData(processed_data);
                
    //             console.log(myData.lat);
    //             console.log(myData.long);

    //             position = [myData.lat,myData.long];
    //         })

    //         .catch((error) => {
    //             console.log(error);
    //         });
    // },[myData]);

    const apiData = useSelector(state => state.api);
    // console.log(apiData);

    const processed_data = [...apiData.coordinate];
    // console.log(processed_data);
   

    const processed_lat = [...apiData.lat];
    // console.log(processed_lat);
    // console.log(processed_lat[0]);
    
    const processed_long = [...apiData.long];
    // console.log(processed_long);
    // console.log(processed_long[0]);

    const processed_nos = [...apiData.nos];
    // console.log(processed_nos);

    const processed_h_dop = [...apiData.h_dop];
    // console.log(processed_h_dop);

    const processed_speed = [...apiData.speed];
    // console.log(processed_speed);

    const processed_n_s = [...apiData.n_s];
    // console.log(processed_n_s);

    const processed_e_w = [...apiData.e_w];
    // console.log(processed_e_w);

    const processed_when = [...apiData.when];
    // console.log(processed_when);

    const processed_epoch = [...apiData.epoch];
    // console.log(processed_epoch);

    
    
    const processed_humid = [...apiData.map_humidity];
    // console.log(processed_humid)
    let processed_humidity =[];
    processed_humid.forEach(ele =>{
      processed_humidity.push([processed_humid[ele]]);
    });
    // console.log(processed_humidity);



    
    const processed_temperature = [...apiData.map_temperature];
    const processed_lightexposure = [...apiData.map_lightexposure];
    const processed_motion = [...apiData.map_motion];
    const processed_altitude = [...apiData.map_altitude];
    const processed_course = [...apiData.map_course];

    
    // const center = processed_data.length>0? processed_data[0]:[28.44372749,77.05503845];
    
    
    
    const center = defaultLocation.val;


    
    
    if(defaultLocation.asked == false) {
      let prompt1 = prompt("ENTER THE CENTER VALUE","28.44372749,77.05503845");
      console.log(prompt1);
      let newVal = [28.44372749,77.05503845];
      try {
        if(!prompt1) throw new Error('Bad prompt result'); // if null, empty string or undefined
        prompt1 = "[" + prompt1 + "]";
        newVal = JSON.parse(prompt1);

        // type of newval should be array
        // length of newval should be 2
        // each element of array should be integer

        // console.log("correctly parsed",newVal)
      }
      catch(e) {
        console.log("unable to parse", e);
      }
      setDefaultLocation({
        asked: true,
        val: newVal
      });
    }
      








      // setDefaultLocation(prompt("ENTER THE CENTER VALUE",[28.44372749,77.05503845]));

      // useEffect(()=>{
        
      //     // center1 = defaultLocation!=null? defaultLocation:processed_data[0];
      //   if(defaultLocation != null)
      //   center1 = defaultLocation;
      //   else if(defaultLocation == null)
      //   center1 = processed_data[0];
        
        
      //   console.log(center1)
        
      // },[defaultLocation]);

      // const center = center1;










      // if(prompt1 != null)
      // center = prompt1;
      // else if(prompt1 == null)
      // center = processed_data[0];
      // setDefaultLocation(prompt("ENTER THE CENTER VALUE",[28.44372749,77.05503845]));
  
      // const center = defaultLocation.length>0?center1:processed_data[0];
      // console.log(center)
  

    const polyline = processed_data.map((e,i)=>{
      return {
      coords : e,
      latitude : processed_lat[i],
      longitude : processed_long[i],
      humidity : processed_humid[i],
      temperature : processed_temperature[i],
      lightexposure : processed_lightexposure[i],
      motion : processed_motion[i],
      h_dop : processed_h_dop[i],
      speed : processed_speed[i],
      altitude : processed_altitude[i],
      course : processed_course[i],
      n_s : String.fromCharCode(processed_n_s[i]),                  // String.fromCharCode() -> this function is used to convert number into ASCII values
      e_w : String.fromCharCode(processed_e_w[i]),
      nos : processed_nos[i],
      date :new Date(processed_epoch[i]*1000).toISOString(),               // epoch time is in seconds, we are converting it into milliseconds by multiplying by 1000
      marker_no : ++i,
      }
    }).reverse();
    console.log(polyline);


    const download_CSV = () =>{
      

        calculated_Drift = [];

      for (let i = 0; i < processed_data.length; i++) {

        function toRad(x) {
          return x * Math.PI / 180;
        }
          
  
            // JavaScript program to calculate Distance Between
            // Two Points on Earth
  
        function distance(lat1,lat2, lon1, lon2)
        {
          
  
          // Haversine formula
          let x2 = lon2 - lon1;
          let dLon = toRad(x2) ;
          let x1 = lat2 - lat1;
          let dLat = toRad(x1);
          let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
            
          let c =  2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
          // Radius of earth in kilometers. Use 3956
          // for miles
          let r = 6371*1000;
  
          // calculate the result
          return(c * r);
        }
  
        // Driver code
          
          let lat1 = processed_lat[0];
          let lat2 = processed_lat[i];
          let lon1 = processed_long[0];
          let lon2 = processed_long[i];
          let calculated_Distance = distance(lat1, lat2,lon1, lon2);
          // console.log(calculated_Distance + " M");
          
          calculated_Drift.push({ "center" : center,
                                  "latitude" : processed_lat[i],
                                  "longitude" : processed_long[i],
                                  "Drift in Meter" : calculated_Distance,
                                  "NOS" : processed_nos[i],
                                  "H_DOP" : processed_h_dop[i],
                                  "SPEED" : processed_speed[i],
                                  "NORTH_SOUTH" : processed_n_s[i],
                                  "EAST_WEST" : processed_e_w[i],
                                  "DATE_TIME" : processed_when[i],
                                  "EPOCH in seconds" : processed_epoch[i]
                                });
  
      }
  
      // console.log(calculated_Drift);

      setTimeout(()=>{
      csvDownloadRef.current.link.click();


      },3000)

      

    };
    
    


    
    // console.log(polyline);

    // console.log(processed_data);
    
    
    // useEffect(() => {

    //       axios.get(`https://api.geocode.earth/v1/reverse?point.lat=${processed_lat[0]}&point.lon=${processed_long[0]}&sources=osm&layers=venue&size=1&api_key=ge-1e618cf3d7bd023d`)
    //           .then((response) =>{
    //               console.log(response.data)
    //               const processed_location = {
    //                   city: [],
    //                   state: [],
    //                   country: [],
    //                   neighbourhood: [],
    //                   postcode: [],
    //                   suburb: [],
    //                   city_district: [],
    //                   label: [],
    //               }
                  
    //               //       processed_location.city.push(response.data.address.city);
    //               //       processed_location.state.push(response.data.address.state);
    //               //       processed_location.country.push(response.data.address.country);
    //               //       processed_location.neighbourhood.push(response.data.address.neighbourhood);
    //               //       processed_location.postcode.push(response.data.address.postcode);
    //               //       processed_location.suburb.push(response.data.address.suburb);
    //               //       processed_location.city_district.push(response.data.address.city_district);
    //                     processed_location.label.push(response.data.label);
                  
    //               console.log(processed_location.label);

    //               // setmyData(processed_location);

                  
                
    //           })
  
    //           .catch((error) => {
    //               console.log(error);
    //           });
    //   });

    
  

  
  



const fillBlueOptions = { fillColor: 'blue' }
const blackOptions = { color: 'black' }
const limeOptions = { color: 'lime' }
const purpleOptions = { color: 'purple' }
const redOptions = { color: 'red' }


const listItems = polyline.map((ele) =>
  <CircleMarker center={ele.coords} pathOptions={purpleOptions} radius={5} eventHandlers={{
    mouseover: (event) => event.target.openPopup(),
    mouseout: (event) => event.target.closePopup()
  }}>

      <Popup>{/* Coordinates : {String(ele.coords)} */} Marker No : {ele.marker_no} <br/> <br/> Lat : {ele.latitude}, <br/> N_S : {ele.n_s}, <br/> Lon : {ele.longitude}, <br/> E_W : {ele.e_w}, <br/> Humidity : {ele.humidity},<br/> Temperature : {ele.temperature},<br/> ALS : {ele.lightexposure},<br/> Evt : {ele.motion}, <br/> H_DOP : {ele.h_dop}, <br/> Speed : {ele.speed}, <br/> Altitude : {ele.altitude}, <br/> Course : {ele.course}, <br/> NoS : {ele.nos}, <br/> Date : {ele.date.split("T")[0]}, Time : {ele.date.split("T")[1].split(".000Z")} </Popup>
  </CircleMarker>
);

// L.Circle({center},{
//   color: 'streetblue',
//   radius: 100,
//   fillColor: 'streetblue',
//   opacity: 0.5,
// }).addTo(this.map)






  return (
    <>  
        <Getdata mac={mac} limit={limit}/>
        <Header mac={mac} limit={limit} csv={download_CSV}  />
        <MapContainer center={center} zoom={16} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          
      
          <Polyline pathOptions={blackOptions} positions={processed_data.reverse()} >
              {listItems}
          </Polyline>  
          
          <Circle 
                  center={center}
                  // fillColor="red" 
                  color ="red"
                  radius={300}
                  
                  />

          <Circle 
                  center={center}
                  // fillColor="blue" 
                  color ="yellow"
                  radius={200}
                  
                  />

          <Circle 
                  center={center}
                  // fillColor="green" 
                  color ="green"
                  radius={100}
                  
                  />

          <Circle 
                  center={center}
                  // fillColor="red" 
                  color ="white"
                  radius={50}
                  
                  />           
        </MapContainer>
      

      <div id="mapdata">
        
        <div className="route-location">
            
          <div className="route-map-icons">
            {/* <IconContext.Provider value={{ className: 'route-map-icons1' }}>
                            
              <CgArrowLongDownC />
                    
            </IconContext.Provider> */}



            {/* <IconContext.Provider value={{ className: 'route-map-icons1' }}>
                            
              <CgShapeCircle />
                    
            </IconContext.Provider> */}
          </div>
            
          <div className="route-map-data">
            {/* <h4>Gurugram, Haryana, India</h4>
            <h6>starting point</h6> */}
          </div>

        </div>

        
        <div className="route-data">

        </div>
      </div>

      <div>
      <CSVLink
          data={calculated_Drift}
          filename="Drifting_distance.csv"
          target="_blank"
          ref={csvDownloadRef}/>

          

            {/* <button className="btn-drift-csv" onClick={download_CSV}>Download Drift CSV</button> */}
      </div>

      <div id="mapfooter">
        <Footer mac={mac} limit={limit}  />
      </div>
      
    </>
  );
}

export default Maptesting;
