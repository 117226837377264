import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setERR } from "../redux/chartoptions";
import IndividualComponentPopup from "./individualcomponentpopup";


function Err(){
   
    const [showModal,setShowModal] = useState(false);
    
    const myData = useSelector(state => state.api);

    let current_Err = myData.Err[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.Err)

        
    const showPopup = () =>{
        setShowModal(true);
    }

    const hidePopup = () =>{
        setShowModal(false);
    }
    

    return(
        <>
            <div className="parameter" id="err" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="err" checked={checked} onChange={(e) => {dispatch(setERR(e.target.checked))}} />
                    <label htmlFor="err" >Err</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current Err : {parseFloat(current_Err).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#a0bfe0"} />}
        </>
    );
}

export default Err;