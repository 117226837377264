import { createSlice } from '@reduxjs/toolkit'

export const offlineApiSlice = createSlice({
  name: 'offlineApi',
  initialState: {
    offline_Humidity : [],
    offline_Temperature : [],
    offline_Lightexposure : [],
    offline_Motion : [],
    // offline_Time : [],
    // offline_Coordinate : [],
    offline_Lat : [],
    offline_Long : [],
    offline_Nos : [],
    offline_H_dop : [],
    offline_Speed : [],
    offline_N_S : [],
    offline_E_W : [],
    // offline_When : [],
    offline_Epoch : [],
    // offline_Battery :[],
    offline_Voltage :[],
    offline_Pressure : [],
    offline_Altitude : [],
    offline_Gx : [],
    offline_Gy : [],
    offline_Gz : [],
    offline_Err : [],
    offline_IMEI : [],
    offline_CSQ : [],
    offline_course : [],
    offline_FWv : [],
    offline_RR : [],
    offline_GSM_Err : [],
    // offline_map_humidity : [],
    // offline_map_temperature : [],
    // offline_map_lightexposure : [],
    // offline_map_motion : [],
    // offline_map_altitude : [],
    // offline_map_course : [],
    payloads : [],
    unstructured_data : [],
    
  },
  
  reducers: {
   setOfflineApiData: (state, action) => {
    return action.payload;
   },
  }
})

export const { setOfflineApiData } = offlineApiSlice.actions

export default offlineApiSlice.reducer

