import IndividualComponentPopup from "./individualcomponentpopup";
import { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setOfflineHumidity } from "../redux/chartoptions";

let h_avg=0;
let h_min=0;
let h_max=0;

function OfflineHumidity(){

    
    const [showModal,setShowModal] = useState(false);
   


    const myData = useSelector(state => state.offlineApi);

        let sum=0;
        
         h_max = Math.max(...myData.offline_Humidity);
         h_min = Math.min(...myData.offline_Humidity);


        // console.log(Math.max(...myData.humidity));
        // console.log(Math.min(...myData.humidity));

        myData.offline_Humidity.forEach(num =>{
            // if (isNaN(num)) {
            //     num = 0;
            // }
            
            sum+=num;
        })
        
        h_avg = sum/Object.keys(myData.offline_Humidity).length;
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_Humidity)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
        

    return(
        <>
            <div className="parameter" id="offline-humidity" onClick={showPopup}>                    
                    <div className="label2">
                        <input type="checkbox" id="offlinehumidity" checked={checked} onChange={(e) => {
                            dispatch(setOfflineHumidity(e.target.checked))}} />
                        <label htmlFor="humidity" >Offline &shy;Humidity</label>
                        
                    </div>
                    
                    <div className="parameter_ul">  
                        <ul>
                            <li type="none">{h_min.toFixed(2)} <br /> min</li>
                            <li type="none">{h_max.toFixed(2)} <br /> max</li>
                            <li type="none">{h_avg.toFixed(2)} <br /> avg</li>
                            
                        </ul>
                    </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#6b77e359"} />}
        </>
    );
}

export default OfflineHumidity;