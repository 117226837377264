import { useEffect,useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { setHumidity } from "../redux/chartoptions";
import {minChartBoundaries, maxChartBoundaries} from "./graph";
import IndividualComponentPopup from "./individualcomponentpopup";


let h_avg=0;
let h_min=0;
let h_max=0;

function Humidity(props){
    console.log(props.data);
    console.log(minChartBoundaries);
    console.log(maxChartBoundaries);

    const [averageHumidity,setAverageHumidity] = useState(0);
    const [minimumHumidity,setminimumHumidity] = useState(0);
    const [maximumHumidity,setmaximumHumidity] = useState(0);

    const [showModal,setShowModal] = useState(false);
    // const [changeColor, setChangecolorn] = useState("#6b77e3cc");
    

    const myData = useSelector(state => state.api);

    useEffect(()=>{

        // if(minChartBoundaries === undefined && maxChartBoundaries === undefined){
        
            
    
        //     let sum=0;
            
        //      h_max = Math.max(...myData.humidity);
        //      setTimeout(() => {
        //         setmaximumHumidity(h_max);
        //      }, 300);
             
    
        //      h_min = Math.min(...myData.humidity);
        //      setTimeout(() => {
        //         setminimumHumidity(h_min);
        //      }, 300);
             
    
    
        //     // console.log(Math.max(...myData.humidity));
        //     // console.log(Math.min(...myData.humidity));
    
        //     myData.humidity.forEach(num =>{
        //         // if (isNaN(num)) {
        //         //     num = 0;
        //         // }
                
        //         sum+=num;
        //     })
            
        //     h_avg = sum/Object.keys(myData.humidity).length;
        //     setTimeout(() => {
        //         setAverageHumidity(h_avg);
        //     }, 300);
            
            
        // }
    
    
        // else{
    
            
          
            
        // }
        
        setTimeout(() => {
            let humidity = myData.humidity;
            // console.log(humidity)
    
            let dates = myData.time;
            // console.log(dates);
    
    
            let sum=0;
    
            let zoomstart_index = -1;
            let i=0;
              
              while(i<dates.length){
                if(dates[i]>=minChartBoundaries){
                  zoomstart_index = i;
                  break;
                }
                i++;
              }
              console.log(zoomstart_index);
    
              let zoomend_index = -1;
              let j=0;
              while(j<dates.length){
                if(dates[j]>=maxChartBoundaries){
                  zoomend_index = j;
                  break;
                }
                j++;
              }

              console.log(zoomend_index);
    
              let filterzoomedhumidity = humidity.slice(zoomstart_index, zoomend_index);
              console.log(filterzoomedhumidity);
            
             h_max = Math.max(...filterzoomedhumidity);
             setTimeout(() => {
                setmaximumHumidity(h_max);
             }, 300);
             
    
             
             h_min = Math.min(...filterzoomedhumidity);
             setTimeout(() => {
                setminimumHumidity(h_min);
             }, 300);
             
    
    
    
            filterzoomedhumidity.forEach(num =>{
                // if (isNaN(num)) {
                //     num = 0;
                // }
                
                sum+=num;
            })
            
            h_avg = sum/Object.keys(filterzoomedhumidity).length;
            setTimeout(() => {
                setAverageHumidity(h_avg);
            }, 300);
            
            
            
        }, 300);

    },[minChartBoundaries,maxChartBoundaries]);
   
   
    
    const dispatch = useDispatch();
    const checked = useSelector(state => state.chartOption.humidity);

    const showPopup = () =>{
        setShowModal(true);
    }

    const hidePopup = () =>{
        setShowModal(false);
    }
    

    return(
        <>
            <div className="parameter" id="humid" onClick={showPopup}>               
                    <div className="label2">
                        <input type="checkbox" id="humidity" checked={checked} onChange={(e) => {
                            dispatch(setHumidity(e.target.checked))}} />
                        <label htmlFor="humidity" >Humi&shy;dity</label>
                        
                    </div>
                    
                    <div className="parameter_ul">  
                        <ul>
                            <li type="none">{minimumHumidity.toFixed(2)} <br /> min</li>
                            <li type="none">{maximumHumidity.toFixed(2)} <br /> max</li>
                            <li type="none">{averageHumidity.toFixed(2)} <br /> avg</li>
                            
                        </ul>
                    </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#6b77e3cc"} name={"Humi&shy;dity"} />}
        </>
    );
}

export default Humidity;