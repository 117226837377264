import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setOfflineApiData } from '../redux/offlineapidata';

function Offlineapi({mac,limit}) {

    const dispatch = useDispatch();

    const [count, setCount] = useState(false);
    
    
    let mainPayload;


    useEffect(()=>{
        setTimeout(()=>{
            axios.get(`http://app.napinotech.com:3000/app/v1/data/offline/stem/${mac}/${limit}`, {maxContentLength: Infinity,  maxBodyLength: Infinity})
            .then((response)=>{
                // console.log(response.data);

                let count_Local = count;

                const processed_offline_data = {
                    offline_Humidity : [],
                    offline_Temperature : [],
                    offline_Lightexposure : [],
                    offline_Motion : [],
                    // offline_Time : [],
                    // offline_Coordinate : [],
                    offline_Lat : [],
                    offline_Long : [],
                    offline_Nos : [],
                    offline_H_dop : [],
                    offline_Speed : [],
                    offline_N_S : [],
                    offline_E_W : [],
                    // offline_When : [],
                    offline_Epoch : [],
                    // offline_Battery :[],
                    offline_Voltage :[],
                    offline_Pressure : [],
                    offline_Altitude : [],
                    offline_Gx : [],
                    offline_Gy : [],
                    offline_Gz : [],
                    offline_Err : [],
                    offline_IMEI : [],
                    offline_CSQ : [],
                    offline_course : [],
                    offline_FWv : [],
                    offline_RR : [],
                    offline_GSM_Err : [],
                    // offline_map_humidity : [],
                    // offline_map_temperature : [],
                    // offline_map_lightexposure : [],
                    // offline_map_motion : [],
                    // offline_map_altitude : [],
                    // offline_map_course : [],
                    payloads : [],
                    unstructured_data : [],
                    
                }
                
                /********************************************************************* LOGIC FOR (SOF-EOF-UNSTRUCTURED DATA) ****************************************************************************************/

                for(let i=0; i<response.data.length; i++){
                    
                    // console.log(response.data[i].payload.T);
                    // if(response.data[i].payload === "**EOF**\r\n"){
                    //     console.log("hello baby");
                        
                    // }
                    // if(response.data[i].payload.match(""))

                    

                    // if(response.data[i].payload != "**EOF**\r\n"){

                    //     // var mainPayload = response.data[i].payload.split("**SOF**");
                    //     // var slashRemoved = mainPayload[1].replace(/\\/g, "");
                    //     // console.log(response.data[i].payload);
                        


                    //     mainPayload = response.data[i].payload.split("**SOF** ");               // split() function returns a new array
                    //     console.log(mainPayload[1]);
                        
                    //     // count = mainPayload.Length;
                    //     // console.log(count);

                        

                    //     mainPayload = mainPayload.splice(1,1);                                      // splice() is used to add/remove array elements, it overwrites the original array. Also it returns the removed elements
                    //     // console.log(mainPayload[0]);
                    //     // console.log(typeof mainPayload[0]);

                    //     // console.log(mainPayload[0].indexOf("}"));
                    //     mainPayload = mainPayload[0].replace(/}/g, "}ENDING");                      // replacing "}" with "}ENDING" at the end of every string so that we can use split() function on "ENDING" in order to get separate objects. 
                    //     mainPayload = mainPayload.split("ENDING");                                  // split() function returns a new array
                    //     // console.log( typeof mainPayload);
                    //     mainPayload.splice(-1,1);                                                   // removing empty elements from array by using splice
                    //     // console.log(mainPayload);
                    //     // console.log(JSON.parse(mainPayload));
                    //     // let indexOfClosingBracket = mainPayload[0].indexOf("}");
                    //     // let example = mainPayload[0].split("}");
                    //     // console.log(example);


                    //     // for(let i=0; i<mainPayload[0].length;i++){
                    //     //     console.log(mainPayload[0][i]);
                    //     // }
                        


                    //     // let regex = /\s*(\"\{.*\}\")\s*/g;
                    //     // mainPayload = mainPayload[0].split(regex);
                    //     // console.log(mainPayload);
                        


                    //     // if(mainPayload[1].match())
                        

                    //     // let changedPayload = mainPayload;
                    //     // let changedPayload = mainPayload.replace(/}/, '}1,');
                    //     // console.log(changedPayload);
                        
                    //     // changedPayload = changedPayload.split("1,");
                    //     // console.log(changedPayload);

                    //     // if(){

                    //     // }
                    //     // mainPayload = mainPayload.split()
                    //     // console.log(mainPayload[1]);
                    //     // console.log(JSON.parse(mainPayload[1]));
                    //     // var slashRemoved = mainPayload.replace(/\\/g,"");
                    //     // console.log(slashRemoved);
                    //     // var slashRemoved = mainPayload[1].
                        
                    //     for(let i=0; i<mainPayload.length; i++){
                    //         processed_offline_data.payload.push(JSON.parse(mainPayload[i]));
                    //     }   
                        
                    // }


                    if(response.data[i].payload.match(/SOF/gi)){

                        if(response.data[i].payload.match(/�/gi)){
                            count_Local = false;
                            continue;
                        }

                        else if(response.data[i].payload.match(/fota/gi)){
                            count_Local = false;
                            continue;
                        }

                        else if(response.data[i].payload.match(/as/gi)){
                            count_Local = false;
                            continue;
                        }

                        else if(count_Local){
                            
                            mainPayload = response.data[i].payload.split("**SOF** ");               // split() function returns a new array
                            // console.log(mainPayload[1]);

                            mainPayload = mainPayload.splice(1,1);                                      // splice() is used to add/remove array elements, it overwrites the original array. Also it returns the removed elements

                            mainPayload = mainPayload[0].replace(/}/g, "}ENDING");                      // replacing "}" with "}ENDING" at the end of every string so that we can use split() function on "ENDING" in order to get separate objects. 
                            mainPayload = mainPayload.split("ENDING");                                  // split() function returns a new array
                    
                            mainPayload.splice(-1,1);                                                   // removing empty elements from array by using splice

                            for(let i=0; i<mainPayload.length; i++){
                                processed_offline_data.payloads.push(JSON.parse(mainPayload[i]));
                            }   
                            
                            // setCount(false);
                            count_Local = false;
                        }
                    }

                    else if(response.data[i].payload.match(/EOF/gi)){
                        // setCount(true);
                        count_Local = true;
                    }

                    else{

                        mainPayload = response.data[i].payload;                                         // split() function returns a new array
                        processed_offline_data.unstructured_data.push(mainPayload);
                        
                    }
                    

                }


                /***************************************************************************************************************************************************************************************/

                // console.log(processed_offline_data.payload);
                // console.log(processed_offline_data.unstructured_data);
                // var payload1 = processed_offline_data.payload[0].split("\\");
                // console.log(payload1[0]);





                // response.data.forEach(ele => {
                    
                //     console.log(payload[ele]);
                // });



                // var data1 = response.data;
                // data1.replace("\\","");

                // var data = response.data.replace("\\","");
                
                // console.log(response.data[0].payload);
                // console.log(response.data[1].payload);



                processed_offline_data.payloads.forEach(ele =>{
                    // console.log(Object.values(processed_offline_data.payload.offline_Epoch[ele]));
                    // Object.keys(data).map(key => data[key] *= 5 )
                    // console.log(ele.EPOCH);
                    ele.EPOCH = ele.EPOCH * 1000;

                })

                // console.log(processed_offline_data.payload);


                processed_offline_data.payloads.forEach(element => {

                    processed_offline_data.offline_Altitude.push(element.Alt);
                    processed_offline_data.offline_Voltage.push(element.BAT);
                    processed_offline_data.offline_CSQ.push(element.CSQ);
                    processed_offline_data.offline_course.push(element.Course);
                    processed_offline_data.offline_Epoch.push(element.EPOCH);
                    processed_offline_data.offline_E_W.push(element.E_W);
                    processed_offline_data.offline_Err.push(element.Err);
                    processed_offline_data.offline_Motion.push(element.Evt);
                    processed_offline_data.offline_FWv.push(element.FWv);
                    processed_offline_data.offline_GSM_Err.push(element.GSM_Err);
                    processed_offline_data.offline_Gx.push(element.Gx);
                    processed_offline_data.offline_Gy.push(element.Gy);
                    processed_offline_data.offline_Gz.push(element.Gz);
                    processed_offline_data.offline_Humidity.push(element.H);
                    processed_offline_data.offline_H_dop.push(element["H-DOP"]);
                    processed_offline_data.offline_IMEI.push(Number(element.IMEI));
                    processed_offline_data.offline_Lat.push(element.Lat);
                    processed_offline_data.offline_Long.push(element.Lon);
                    processed_offline_data.offline_Lightexposure.push(element.Lux);
                    processed_offline_data.offline_N_S.push(element.N_S);
                    processed_offline_data.offline_Nos.push(element.NoS);
                    processed_offline_data.offline_Pressure.push(element.P);
                    processed_offline_data.offline_RR.push(element.RR);
                    processed_offline_data.offline_Speed.push(element.Speed);
                    processed_offline_data.offline_Temperature.push(element.T);
                    
                });

                setCount(count_Local);
                // console.log(processed_offline_data);

                dispatch(setOfflineApiData(processed_offline_data));
                
            })
            .catch((error)=>{
                console.log(error);
            })
        },1000);
    },[count]);
  return (
    <div>
      
    </div>
  )
}

export default Offlineapi
