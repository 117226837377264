import React from "react";
import axios from "axios";
import Humidity from "./humidity";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import apidata, { setApiData } from '../redux/apidata';
import { setOfflineApiData } from '../redux/offlineapidata';
import { setHumidity } from "../redux/chartoptions";
import { setTemperature } from "../redux/chartoptions";
import { setMotion } from "../redux/chartoptions";
import { setLightExposure } from "../redux/chartoptions";
import { setVoltage } from "../redux/chartoptions";
import { setPressure } from "../redux/chartoptions";
import { setGX } from "../redux/chartoptions";
import { setGY } from "../redux/chartoptions";
import { setGZ } from "../redux/chartoptions";
import { setERR } from "../redux/chartoptions";
import { setCSQ } from "../redux/chartoptions";
import { setRR } from "../redux/chartoptions";

import { setOfflineHumidity } from "../redux/chartoptions";
import { setOfflineTemperature } from "../redux/chartoptions";
import { setOfflineLightExposure } from "../redux/chartoptions";
import { setOfflineMotion } from "../redux/chartoptions";
import { setOfflineVoltage } from "../redux/chartoptions";
import { setOfflinePressure } from "../redux/chartoptions";
// import { setOfflineAltitude } from "../redux/chartoptions";
import { setOfflineGX } from "../redux/chartoptions";
import { setOfflineGY } from "../redux/chartoptions";
import { setOfflineGZ } from "../redux/chartoptions";
import { setOfflineERR } from "../redux/chartoptions";
import { setOfflineCSQ } from "../redux/chartoptions";
import { setOfflineRR } from "../redux/chartoptions";
// import { setOfflineGSMErr } from "../redux/chartoptions";

import 'chartjs-adapter-luxon';
import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// const { jsPDF } = require("jspdf");
import {StreamingPlugin, RealTimeScale} from "@robloche/chartjs-plugin-streaming";
// import { setZoomedValuesData } from "../redux/zoomedvalues";



import {
  Chart as ChartJS,
  CategoryScale,
  LineController,
  PointElement,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  Colors,
  
} from "chart.js";


import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';
import { Line } from "react-chartjs-2";
import Getdata from "./getdata";

ChartJS.register(
  CategoryScale,
  LineController,
  PointElement,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  zoomPlugin,
  Colors,
  StreamingPlugin,
  RealTimeScale,

);

let datapoints_count;
let minChartBoundaries;
let maxChartBoundaries;

function Graph({mac, limit, updater}) {
  
  const dispatch = useDispatch();

  
  // function minimumZoomedValue(){
  //   // set to visible data state 
  //   // call the updater for other functions
  // }


  // function maximumZoomedValue(){
    
  // }

  

  const options = {
    responsive: true,
    plugins: {
      tooltip:{
        // enabled: false
      },
      colors: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      zoom: {
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: '#34ebd5',
          },
          onZoomComplete:  getVisibleData,
          pinch: {
            enabled: true,
            mode: 'x',
          },
          
          mode: 'x',
          
        },
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl'
        }
        
      },
      

    },
    scales: {
      
      y: {
        beginAtZero: true,
      },
      x:{
        id: 'x-axis-0',
        // parsing : false,
        type: 'time',
        
        // type: 'realtime',
        // realtime: {
        //   duration: 20000,
        //   refresh: 1000,
        //   delay: 2000,
        //   onRefresh: chart =>{
        //     axios.get(`http://app.napinotech.com:3000/app/v1/data/stem/${mac}/${limit}`)
        //     .then(response => response.json())
        //     .then(data => {
        //       // append the new data array to the existing chart data
        //       chart.data.datasets[0].data.push(...data);

        //       // update chart datasets keeping the current animation
        //       chart.update('quiet');
        //     });
        //       }
        // },
        bounds: 'ticks',
        time: {
          // Luxon format string
          // tooltipFormat: 'DD T',
          displayFormats: {
            
          //  day:'MMM d',

          },
          // unit: 'hour',
          // unitStepSize: 3,
          
        },
        ticks:{
          major:{
            enabled: true,
            unit:'day',
            fontSize: 20,
            unitStepSize: 1,
            
            displayFormats: {
              'day': 'MMM D HH:mm',
              'hour': 'MMM D'
          },
          },
          font: (context)=>{
            const boldedTicks = context.tick && context.tick.major ? 'bold' : '';
            return { weight: boldedTicks};
          },
          source: 'auto',  
          autoSkip : true,
        },
        title: {
          display: false,
          text: 'Date',
        },
        
      },
    },
   
    maintainAspectRatio : false,
    
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover:{
      mode: 'index',

    },
    elements :{
      point :{
        radius : 2,
        pointstyle: 'circle',
        hoverRadius: 4,
      },
      line:{
        
      }
    }
  };

/************************************************************************************************** FETCHING ONLINE API DATA FROM REDUX STORE ***********************************************************************************************************************/

  const apiData = useSelector(state => state.api); // returns a read only (immutable) version of the arrays
  // console.log(apiData);
  // const processed_data = {...apiData} // doesn't work (Tell me the reason = Shallow copy & deep copy reasons)
  const processed_data = JSON.parse(JSON.stringify(apiData));
  // console.log(processed_data);
  const visibilityData = useSelector(state => state.chartOption);
  // console.log(visibilityData);

   
  // console.log("Datat from State = ", processed_data);
  // console.log("Datat from State = ", typeof processed_data.time[0], typeof processed_data.time);
  // console.log("Reversed data = ", processed_data.time.reverse());


  // useEffect(() => {
/*******************************************************************************************************************************************************************************************************************************************************************/

/************************************************************************************************** FETCHING OFFLINE API DATA FROM REDUX STORE *********************************************************************************************************************/
  
  const offline_Api_Data = useSelector(state => state.offlineApi);
  // console.log(offline_Api_Data);
  
  const reversed_Payload = Object.values(offline_Api_Data.payloads).reverse();
  // console.log(reversed_Payload);

  

/*******************************************************************************************************************************************************************************************************************************************************************/  



          const dates = processed_data.time.reverse();
          // console.log(dates);
          const datapoints = [processed_data.humidity.reverse(),processed_data.temperature.reverse(),processed_data.lightexposure.reverse(),processed_data.motion.reverse(),processed_data.battery.reverse(),processed_data.voltage.reverse(), processed_data.pressure.reverse(), processed_data.Gx.reverse(), processed_data.Gy.reverse(), processed_data.Gz.reverse(), processed_data.Err.reverse(), processed_data.IMEI.reverse(), processed_data.CSQ.reverse(), processed_data.FWv.reverse(), processed_data.RR.reverse()];

          const motion = processed_data.motion.reverse();
          const down = (ctx, value) =>{
            // console.log(ctx);
            // console.log(ctx.p0.parsed.y);
            if(ctx.p1.parsed.y > ctx.p0.parsed.y){
              if(ctx.p1.parsed.y == 1){
                return value[1];
              }
              if(ctx.p1.parsed.y == 2){
                return value[2];
              }
              if(ctx.p1.parsed.y == 3){
                return value[3];
              }
            }
            if(ctx.p1.parsed.y < ctx.p0.parsed.y){
              if(ctx.p1.parsed.y == 0){
                return value[0];
              }
              if(ctx.p1.parsed.y == 1){
                return value[1];
              }
              if(ctx.p1.parsed.y == 2){
                return value[2];
              }
            }
            if(ctx.p1.parsed.y == ctx.p0.parsed.y){
              if(ctx.p1.parsed.y == 0){
                return value[0];
              }
              if(ctx.p1.parsed.y == 1){
                return value[1];
              }
              if(ctx.p1.parsed.y == 2){
                return value[2];
              }
              if(ctx.p1.parsed.y == 3){
                return value[3];
              }
            }
            
            else 
            return undefined;
          }

          

          const data = {
            labels: dates,
            datasets: [
              {
                label: "humidity",
                hidden : !visibilityData.humidity,
                data: datapoints[0],
                backgroundColor: "#757dc6",
                hoverBackgroundColor: "#5059ad",
                borderColor: "#5059ad",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "temperature",
                hidden : !visibilityData.temperature,
                data: datapoints[1],
                backgroundColor: "#de3c63",
                hoverBackgroundColor: "#c40634",
                borderColor: "#c40634",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "lightexposure",
                hidden : !visibilityData.lightexposure,
                data: datapoints[2],
                backgroundColor: "#89f0ca",
                hoverBackgroundColor: "#37cb95",
                borderColor: "#37cb95",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "motion",
                hidden : !visibilityData.motion,
                data: datapoints[3],
                backgroundColor: "#c3efdf",
                hoverBackgroundColor: "#abf7db",
                // borderColor: "#c3efdf",
                borderWidth: 1,
                tension: 0.4,
                segment:{
                  borderColor: ctx => down(ctx, ["#19F7DC", "#F76728", "#F7CD2D", "#97F56C"]) || "rgba(75,192,192,1)",
                }
              },
              {
                // label: "battery",
                // hidden : !visibilityData.battery,
                // data: datapoints[4],
                // backgroundColor: "#FFFF00",
                // hoverBackgroundColor: "#ff9933",
                // borderColor: "#ff9933",
                // borderWidth: 1,
                // tension: 0.4,
              },
              {
                label: "voltage",
                hidden : !visibilityData.voltage,
                data: datapoints[5],
                backgroundColor: "#FFFF00",
                hoverBackgroundColor: "#ff9933",
                borderColor: "#ff9933",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "pressure",
                hidden : !visibilityData.pressure,
                data: datapoints[6],
                backgroundColor: "#91C8E4",
                hoverBackgroundColor: "#4682A9",
                borderColor: "#4682A9",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "Gx",
                hidden : !visibilityData.Gx,
                data: datapoints[7],
                backgroundColor: "#A8A196",
                hoverBackgroundColor: "#7D7463",
                borderColor: "#7D7463",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "Gy",
                hidden : !visibilityData.Gy,
                data: datapoints[8],
                backgroundColor: "#FFD9C0",
                hoverBackgroundColor: "#8CC0DE",
                borderColor: "#8CC0DE",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "Gz",
                hidden : !visibilityData.Gz,
                data: datapoints[9],
                backgroundColor: "#EF6262",
                hoverBackgroundColor: "#862B0D",
                borderColor: "#862B0D",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "Err",
                hidden : !visibilityData.Err,
                data: datapoints[10],
                backgroundColor: "#4A55A2",
                hoverBackgroundColor: "#A0BFE0",
                borderColor: "#A0BFE0",
                borderWidth: 1,
                tension: 0.4,
              // },
              // {
              //   label: "IMEI",
              //   hidden : !visibilityData.IMEI,
              //   data: datapoints[11],
              //   backgroundColor: "#FFFF00",
              //   hoverBackgroundColor: "#ff9933",
              //   borderColor: "#ff9933",
              //   borderWidth: 1,
              //   tension: 0.4,
              },
              {
                label: "CSQ",
                hidden : !visibilityData.CSQ,
                data: datapoints[12],
                backgroundColor: "#FCAEAE",
                hoverBackgroundColor: "#4FC0D0",
                borderColor: "#4FC0D0",
                borderWidth: 1,
                tension: 0.4,
              },
              {
              //   label: "FWv",
              //   hidden : !visibilityData.FWv,
              //   data: datapoints[13],
              //   backgroundColor: "#FFFF00",
              //   hoverBackgroundColor: "#ff9933",
              //   borderColor: "#ff9933",
              //   borderWidth: 1,
              //   tension: 0,
              },
              {
                label: "RR",
                hidden : !visibilityData.RR,
                data: datapoints[14],
                backgroundColor: "#FF90BB",
                hoverBackgroundColor: "#FF2171",
                borderColor: "#FF2171",
                borderWidth: 1,
                tension: 0.4,
              },
              {
                label: "Offline_Humidity",
                hidden : !visibilityData.offline_Humidity,
                data: reversed_Payload,
                backgroundColor: "#757dc6",
                hoverBackgroundColor: "#5059ad",
                borderColor: "#5059ad",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'H',
                },
              },
              {
                label: "Offline_temperature",
                hidden : !visibilityData.offline_Temperature,
                data: reversed_Payload,
                backgroundColor: "#de3c63",
                hoverBackgroundColor: "#c40634",
                borderColor: "#c40634",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'T',
                },
              },
              {
                label: "Offline_lightexposure",
                hidden : !visibilityData.offline_Lightexposure,
                data: reversed_Payload,
                backgroundColor: "#89f0ca",
                hoverBackgroundColor: "#37cb95",
                borderColor: "#37cb95",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Lux',
                },
              },
              {
                label: "Offline_motion",
                hidden : !visibilityData.offline_Motion,
                data: reversed_Payload,
                backgroundColor: "#c3efdf",
                hoverBackgroundColor: "#abf7db",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6],
                  borderColor: ctx => down(ctx, ["#19F7DC", "#F76728", "#F7CD2D", "#97F56C"]) || "rgba(75,192,192,1)",
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Evt',
                },
              },
              // {
              //   label: "Offline_battery",
              //   // hidden : !visibilityData.battery,
              //   data: reversed_Payload,
              //   backgroundColor: "#FFFF00",
              //   hoverBackgroundColor: "#ff9933",
              //   borderColor: "#ff9933",
              //   borderWidth: 1,
              //   segment:{
              //     borderDash: [6,6]
              //   }, 
              //   tension: 0.4,
              //   parsing: {
              //     xAxisKey : 'EPOCH',
              //     yAxisKey : 'BAT',
              //   },
              // },
              {
                label: "Offline_voltage",
                hidden : !visibilityData.offline_Voltage,
                data: reversed_Payload,
                backgroundColor: "#FFFF00",
                hoverBackgroundColor: "#ff9933",
                borderColor: "#ff9933",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'BAT',
                },
              },
              {
                label: "Offline_pressure",
                hidden : !visibilityData.offline_Pressure,
                data: reversed_Payload,
                backgroundColor: "#91C8E4",
                hoverBackgroundColor: "#4682A9",
                borderColor: "#4682A9",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'P',
                },
              },
              {
                label: "Offline_Gx",
                hidden : !visibilityData.offline_Gx,
                data: reversed_Payload,
                backgroundColor: "#A8A196",
                hoverBackgroundColor: "#7D7463",
                borderColor: "#7D7463",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Gx',
                },
              },
              {
                label: "Offline_Gy",
                hidden : !visibilityData.offline_Gy,
                data: reversed_Payload,
                backgroundColor: "#FFD9C0",
                hoverBackgroundColor: "#8CC0DE",
                borderColor: "#8CC0DE",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Gy',
                },
              },
              {
                label: "Offline_Gz",
                hidden : !visibilityData.offline_Gz,
                data: reversed_Payload,
                backgroundColor: "#EF6262",
                hoverBackgroundColor: "#862B0D",
                borderColor: "#862B0D",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Gz',
                },
              },
              {
                label: "Offline_Err",
                hidden : !visibilityData.offline_Err,
                data: reversed_Payload,
                backgroundColor: "#4A55A2",
                hoverBackgroundColor: "#A0BFE0",
                borderColor: "#A0BFE0",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'Err',
                },
              },
              {
              //   label: "Offline_IMEI",
              //   hidden : !visibilityData.IMEI,
              //   data: reversed_Payload,
              //   backgroundColor: "#FFFF00",
              //   hoverBackgroundColor: "#ff9933",
              //   borderColor: "#ff9933",
              //   borderWidth: 1,
              // segment:{
              //   borderDash: [6,6]
              // }, 
              // tension: 0.4,
              // parsing: {
              //   xAxisKey : 'EPOCH',
              //   yAxisKey : 'IMEI',
              // },
              },
              {
                label: "Offline_CSQ",
                hidden : !visibilityData.offline_CSQ,
                data: reversed_Payload,
                backgroundColor: "#FCAEAE",
                hoverBackgroundColor: "#4FC0D0",
                borderColor: "#4FC0D0",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'CSQ',
                },
              },
              {
              //   label: "Offline_FWv",
              //   hidden : !visibilityData.FWv,
              //   data: reversed_Payload,
              //   backgroundColor: "#FFFF00",
              //   hoverBackgroundColor: "#ff9933",
              //   borderColor: "#ff9933",
              //   borderWidth: 1,
              // segment:{
              //   borderDash: [6,6]
              // }, 
              // tension: 0.4,
              // parsing: {
              //   xAxisKey : 'EPOCH',
              //   yAxisKey : 'FWv',
              // },
              },
              {
                label: "Offline_RR",
                hidden : !visibilityData.offline_RR,
                data: reversed_Payload,
                backgroundColor: "#FF90BB",
                hoverBackgroundColor: "#FF2171",
                borderColor: "#FF2171",
                borderWidth: 1,
                segment:{
                  borderDash: [6,6]
                }, 
                tension: 0.4,
                parsing: {
                  xAxisKey : 'EPOCH',
                  yAxisKey : 'RR',
                },
              },
              

              
            ],

            
          };
      
          // console.log(data)
        // setmyData(data)
        
    
  // }, [myData]);

  



      /***************************************** for resetting/zoom-in/zoom-out the graph *******************************************/


          const chartRef = React.useRef(null);   //using useRef hook to make reference for zoom reset
          // const chart = chartRef.current;
          if(chartRef && chartRef.current){
            setTimeout(() => {
              // console.log(chartRef.current.scales.x.min);
              minChartBoundaries = chartRef.current.scales.x.min;
              console.log(minChartBoundaries);
              maxChartBoundaries = chartRef.current.scales.x.max;
              console.log(maxChartBoundaries);

              updater(5);
              
            }, 100);
            
          }
          
          function resetZoomChart(){              // function for resetting the graph
            if (chartRef && chartRef.current) {   // useRef hook always have a "current" object
              chartRef.current.resetZoom({ChartJS}, "reset");
              dispatch(setApiData(apiData));
            chartRef.current.data.labels = processed_data.time;


            /*************************** CLEARING THE INPUT DATE *********************************/
            document.getElementById("startdate").value = "";
            document.getElementById("enddate").value = "";
            /*************************************************************************************/ 
              
            chartRef.current.update();
            }
            
          };
          
          function ZoominChart(){                 // function for zoom-in the graph
            if (chartRef && chartRef.current) {   // useRef hook always have a "current" object
              chartRef.current.zoom(1.19);

               minChartBoundaries = chartRef.current.scales.x.min;
              console.log(minChartBoundaries);

               maxChartBoundaries = chartRef.current.scales.x.max;
              console.log(maxChartBoundaries);
              
              updater(4);
            }
          };

          function ZoomoutChart(){                // function for zoom-out the graph
            if (chartRef && chartRef.current) {   // useRef hook always have a "current" object
              chartRef.current.zoom(0.5);
            }
          };

          
        /******************************************************* filter dates ********************************************************/
        
        
         function filterData(){
          const dates2 = [...dates];
          // console.log(dates2);

          const datapoints2 = [...datapoints];
          // console.log(datapoints2);

          const startdate = document.getElementById('startdate').value;
          //  console.log(startdate);

          const startdate1 = Date.parse(startdate);
          // console.log(startdate1);

          let start_index = -1;
          let i=0;
          while(i<dates2.length){
            if(dates2[i]>=startdate1){
              start_index = i;
              break;
            }
            i++;
          }
          // console.log(start_index);

          const enddate = document.getElementById('enddate').value;
          // console.log(enddate);
          const enddate1 = Date.parse(enddate);
          // console.log(enddate1);

          let end_index = -1;
          let j=0;
          while(j<dates2.length){
            if(dates2[j]>=enddate1){
              end_index = j-1;
              break;
            }
            j++;
          }

          // console.log(end_index);
          


          /* get the index number in array */

          // const indexstartdate = dates2.indexOf(startdate1);
          // const indexenddate = dates2.indexOf(enddate1);
          // console.log(indexstartdate);
          // console.log(indexenddate);
          
          /* poping all the unnecessary dates and keeping only the filtered dates */ 
          // dates2.forEach(ele=>{
          //   if(ele < dates2[] || ele > enddate1){
          //     dates2.pop(ele);
          //   }
          // });
          
          
          

          const filterDate = dates2.slice(start_index, end_index);
          

          const filterhumidity = datapoints2[0].slice(start_index, end_index);
          const filtertemperature = datapoints2[1].slice(start_index, end_index);
          const filterlightexposure = datapoints2[2].slice(start_index, end_index);
          const filtermotion = datapoints2[3].slice(start_index, end_index);

          // console.log(filterhumidity);
          // console.log(filtertemperature);
          // console.log(filterlightexposure);
          // console.log(filtermotion);

          const filterdatapoints = [filterhumidity, filtertemperature, filterlightexposure, filtermotion];
          datapoints_count = filterhumidity.length;
          
          if(chartRef && chartRef.current){
              chartRef.current.data.labels = filterDate;

              chartRef.current.datapoints = filterdatapoints;
              chartRef.current.update();
            } 


            // dispatch(setApiData(processed_data));
        }


        function getVisibleData({chart}){
          
          // console.log(chart.canvas);
          console.log(chart.ctx);

          let xscales = chart.scales.x;
          console.log(xscales);

          let xmin = xscales.min;
          // console.log(xmin);
          
          let xmax = xscales.max;
          // console.log(xmax);

          const dates3 = [...dates];
          // console.log(dates3);

          const datapoints3 = [...datapoints];
          // console.log(datapoints3);

          console.log(chartRef.current.scales.x.min);
          console.log(chartRef.current.scales.x.max);



          /* removing unwanted dates and getting the required dates */
          let zoomstart_index = -1;
          let i=0;
          
          while(i<dates3.length){
            if(dates3[i]>=xmin){
              zoomstart_index = i;
              break;
            }
            i++;
          }
          // console.log(zoomstart_index);


          let zoomend_index = -1;
          let j=0;
          while(j<dates3.length){
            if(dates3[j]>=xmax){
              zoomend_index = j;
              break;
            }
            j++;
          }

          // console.log(zoomend_index);

          /************************************** TESTING *****************************************/
          // let abc = chartRef.current.getInitialScaleBounds();
          // console.log(abc)

          // const meta = chartRef.current.getDatasetMeta(0);
          // console.log(meta);
          // const x = meta.data[0].x;
          // console.log(x);

          /************************************** TESTING *****************************************/


          const filterzoomedDate = dates3.slice(zoomstart_index, zoomend_index);

          const filterzoomedhumidity = datapoints3[0].slice(zoomstart_index, zoomend_index);
          const filterzoomedtemperature = datapoints3[1].slice(zoomstart_index, zoomend_index);
          const filterzoomedlightexposure = datapoints3[2].slice(zoomstart_index, zoomend_index);
          const filterzoomedmotion = datapoints3[3].slice(zoomstart_index, zoomend_index);

          // console.log(filterzoomedhumidity);
          // console.log(filterzoomedtemperature);
          // console.log(filterzoomedlightexposure);
          // console.log(filterzoomedmotion);

          const newprocessed_data = {
            humidity : filterzoomedhumidity,
            temperature : filterzoomedtemperature,
            lightexposure : filterzoomedlightexposure,
            motion : filterzoomedmotion,
            time : filterzoomedDate,
          }
          // console.log(newprocessed_data);
          // dispatch(setApiData(newprocessed_data));
          chart.update();

          datapoints_count = chartRef.current.tooltip.dataPoints[0].dataIndex;
          console.log(datapoints_count);

          // const filterzoomeddatapoints = [filterzoomedhumidity, filterzoomedtemperature, filterzoomedlightexposure, filterzoomedmotion];
          
          // if(chartRef && chartRef.current){
          //     chartRef.current.data.labels = filterzoomedDate;

          //     chartRef.current.datapoints = filterzoomeddatapoints;
          //     chartRef.current.update();
          //   } 

        }

/********************************************************************************************* TOGGLE ONLINE - OFFLINE DATA ***********************************************************************************************************/

function toggleData(value){
  // console.log(value);
  
  if(value === 0){


    dispatch(setHumidity(true));
    dispatch(setTemperature(true));
    dispatch(setMotion(true));
    dispatch(setLightExposure(true));
    dispatch(setVoltage(false));
    dispatch(setPressure(false));
    dispatch(setGX(false));
    dispatch(setGY(false));
    dispatch(setGZ(false));
    dispatch(setERR(false));
    dispatch(setCSQ(false));
    dispatch(setRR(false));
    

    dispatch(setOfflineHumidity(false));
    dispatch(setOfflineTemperature(false));
    dispatch(setOfflineLightExposure(false));
    dispatch(setOfflineMotion(false));
    dispatch(setOfflineVoltage(false));
    dispatch(setOfflinePressure(false));
    dispatch(setOfflineGX(false));
    dispatch(setOfflineGY(false));
    dispatch(setOfflineGZ(false));
    dispatch(setOfflineERR(false));
    dispatch(setOfflineCSQ(false));
    dispatch(setOfflineRR(false));
    chartRef.current.update();
  }

  if(value === 1){

    dispatch(setHumidity(false));
    dispatch(setTemperature(false));
    dispatch(setMotion(false));
    dispatch(setLightExposure(false));
    dispatch(setVoltage(false));
    dispatch(setPressure(false));
    dispatch(setGX(false));
    dispatch(setGY(false));
    dispatch(setGZ(false));
    dispatch(setERR(false));
    dispatch(setCSQ(false));
    dispatch(setRR(false));
    

    dispatch(setOfflineHumidity(true));
    dispatch(setOfflineTemperature(true));
    dispatch(setOfflineLightExposure(true));
    dispatch(setOfflineMotion(true));
    dispatch(setOfflineVoltage(false));
    dispatch(setOfflinePressure(false));
    dispatch(setOfflineGX(false));
    dispatch(setOfflineGY(false));
    dispatch(setOfflineGZ(false));
    dispatch(setOfflineERR(false));
    dispatch(setOfflineCSQ(false));
    dispatch(setOfflineRR(false));
    chartRef.current.update();
  }
  

  if(value === 2){

    dispatch(setHumidity(true));
    dispatch(setTemperature(true));
    dispatch(setMotion(true));
    dispatch(setLightExposure(true));
    dispatch(setVoltage(false));
    dispatch(setPressure(false));
    dispatch(setGX(false));
    dispatch(setGY(false));
    dispatch(setGZ(false));
    dispatch(setERR(false));
    dispatch(setCSQ(false));
    dispatch(setRR(false));
    

    dispatch(setOfflineHumidity(true));
    dispatch(setOfflineTemperature(true));
    dispatch(setOfflineLightExposure(true));
    dispatch(setOfflineMotion(true));
    dispatch(setOfflineVoltage(false));
    dispatch(setOfflinePressure(false));
    dispatch(setOfflineGX(false));
    dispatch(setOfflineGY(false));
    dispatch(setOfflineGZ(false));
    dispatch(setOfflineERR(false));
    dispatch(setOfflineCSQ(false));
    dispatch(setOfflineRR(false));
    chartRef.current.update();
  }

}
/**************************************************************************************************************************************************************************************************************************************/


/******************************************************************************************* OPEN & CLOSE BUTTON FOR GRAPH SIDE PANEL**************************************************************************************************/

function openGraphSidePanelNav() {
  document.getElementById("graphSidePanel").style.width = "250px";
  document.getElementById("closebutton").style.color = "#ffffff";
  // console.log(document.getElementById("graphSidePanel"));

}

function closeGraphSidePanelNav() {
  document.getElementById("graphSidePanel").style.width = "0px";
  document.getElementById("closebutton").style.color = "#000000";
}

/**************************************************************************************************************************************************************************************************************************************/
  return (
    <>
      <div className="graph">
        <div className="graph1">

          <div className="line-chart">
            <Line data={data} options={options} ref={chartRef}/>
          </div>

          <div className="graph-sidepanel-open-button">
            <button className="openbtn" onClick={openGraphSidePanelNav}>☰</button>
          </div>
        </div>

       
        
        <div className="side-panel" id="graphSidePanel">
          {/* <a href="javascript:void(0)" className="closebtn" onclick={()=>{closeGraphSidePanelNav}}>×</a> */}
          <button className="closebtn" id="closebutton" onClick={closeGraphSidePanelNav}>x</button>
          <button onClick={() => toggleData(0)}>Online-API</button>
          <br />
          <button onClick={() => toggleData(1)}>Offline-API</button>
          <br />
          <button onClick={() => toggleData(2)}>Both-API</button>
        </div>

        <div className="button-dates">
            <div id="customdates">
                <input  type="date" id="startdate" />
                <input  type="date" id="enddate" />
                <button className="scheduled_date-btn" type="submit" onClick={filterData}>Submit</button>
            </div>

            <div id="resetbutton">
              <button className="button2" onClick={resetZoomChart}>reset graph</button>
              <button className="button3" onClick={ZoominChart}>zoom in</button>
              <button className="button4" onClick={ZoomoutChart}>zoom out</button>
            </div>
            
            {/* <div className="datapoint_count">
                <h1>{datapoints_count}</h1>
            </div> */}
        </div>
      </div>
    </>
  );
}

export default Graph;

export {minChartBoundaries, maxChartBoundaries};
