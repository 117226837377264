
import { useSelector, useDispatch } from "react-redux";
import { setOfflineLightExposure } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";

let L_avg=0;
let L_min=0;
let L_max=0;

function OfflineLightexposure(){

    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.offlineApi)
        
        let sum=0;
        
         L_max = Math.max(...myData.offline_Lightexposure);
         L_min = Math.min(...myData.offline_Lightexposure);


       

        myData.offline_Lightexposure.forEach(num =>{
            // if (isNaN(num)) {
            //     num = 0;
            // }
            sum+=num;
        })
        
        L_avg = sum/Object.keys(myData.offline_Lightexposure).length;

        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_Lightexposure)
        
        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        
        
       

    return(
        <>
            <div className="parameter" id="offline-lux" onClick={showPopup}>
                <div className="label2">
                    <input type="checkbox" id="offlinelux" checked={checked} onChange={(e) => {dispatch(setOfflineLightExposure(e.target.checked))}} />
                    <label htmlFor="lux" >Offline &shy;Light &shy;Exposure</label>
                </div>

                <div className="parameter_ul">
                    <ul>
                        <li type="none">{L_min.toFixed(2)} <br /> min</li>
                        <li type="none">{L_max.toFixed(2)} <br /> max</li>
                        <li type="none">{L_avg.toFixed(2)} <br /> avg</li>
                                
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#37cb9559"} />}
        </>
    );
}

export default OfflineLightexposure;