import { useDispatch,useSelector } from "react-redux";
import { setOfflineCSQ } from "../redux/chartoptions";
import { useState } from "react";
import IndividualComponentPopup from "./individualcomponentpopup";



function OfflineCSQ(){
   
    const [showModal,setShowModal] = useState(false);

    const myData = useSelector(state => state.offlineApi);

    let current_CSQ = myData.offline_CSQ[0];

        
        
        const dispatch = useDispatch();
        const checked = useSelector(state => state.chartOption.offline_CSQ)

        const showPopup = () =>{
            setShowModal(true);
        }
    
        const hidePopup = () =>{
            setShowModal(false);
        }
        

    return(
        <>
            <div className="parameter" id="offline-csq" onClick={showPopup}>                    
                <div className="label2">
                    <input type="checkbox" id="offlinecsq" checked={checked} onChange={(e) => {dispatch(setOfflineCSQ(e.target.checked))}} />
                    <label htmlFor="offlinecsq" >Offline &shy;CSQ</label>
                </div>
                    
                <div className="parameter_ul">
                    <ul>
                        <li type="none">Current CSQ : {parseFloat(current_CSQ).toFixed(2)} <br/> </li>
                    </ul>
                </div>
            </div>
            {showModal && <IndividualComponentPopup hider={hidePopup} bgcolor={"#4fc1d07c"} />}
        </>
    );
}

export default OfflineCSQ;